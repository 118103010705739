import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

function ConfirmDialog({ show, onHide, title, message, action, data, button }) {
    const [inputValue, setInputValue] = useState('');

    const handleAction = () => {
        setInputValue('');
        if (data) {
            action(data);
        } else {
            action();
        }
        onHide();
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const isDeleteAction = title === 'Delete Category';

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
                {isDeleteAction && (
                    <Form>
                        <Form.Group controlId="categoryName">
                            <Form.Label>Type the category name to confirm:</Form.Label>
                            <Form.Control
                                type="text"
                                value={inputValue}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant={button.variant}
                    onClick={handleAction}
                    disabled={isDeleteAction && inputValue !== data?.categoryName}
                >
                    {button.text}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ConfirmDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    data: PropTypes.object,
    button: PropTypes.shape({
        variant: PropTypes.string,
        text: PropTypes.string
    }).isRequired
};

ConfirmDialog.defaultProps = {
    title: '',
    message: '',
    action: () => {},
    data: undefined,
    button: {
        variant: 'primary',
        text: 'Ok'
    }
};

export default ConfirmDialog;
