import axiosInstance from '../../services/axios-service';
import socketIOClient from 'socket.io-client';
import config from '../../config';

const dashboard_base_url = '/v1/dashboard';

// eslint-disable-next-line no-unused-vars
const socket = socketIOClient(config.base, { transports: ['websocket'] });

class DashboardService {
    getDashboard = () => {
        return axiosInstance.get(dashboard_base_url);
    };

    updateDashboard = (message) => {
        return axiosInstance.post(`${dashboard_base_url}`, message);
    };

    updateSchedule = (formattedMorningTime, formattedEveningTime) => {
        return axiosInstance
            .post('/v1/updateSchedule', {
                morningTime: formattedMorningTime,
                eveningTime: formattedEveningTime
            })
            .then((response) => {
                console.log('Schedule updated!');
                console.log(response);
                return response.data;
            })
            .catch((error) => {
                console.log('Failed to update schedule.');
                console.error('Error:', error);
            });
    };

    getSchedule = () => {
        return axiosInstance
            .get('/v1/getCurrentSchedule')
            .then((response) => {
                console.log('Schedule Getted!');
                console.log(response);
                return response.data;
            })
            .catch((error) => {
                console.log('Failed to update schedule.');
                console.error('Error:', error);
            });
    };

    updateWall = (onError) => {
        try {
            console.log('Update Wall');
            socket.emit('dataChanged', 'hello');
        } catch (error) {
            onError(error);
        }
    };

    onGalaxy = (onError) => {
        try {
            console.log('Toggle Galaxy on');
            socket.emit('hideProjection', { value: 'An%wQ08djo&POtPm' });
        } catch (error) {
            onError(error);
        }
    };

    onWall = (onError) => {
        try {
            console.log('Toggle Wall on');
            socket.emit('hideWall', { value: '0%PK%rEEkZpaU*wu' });
        } catch (error) {
            onError(error);
        }
    };

    offGalaxy = (onError) => {
        try {
            console.log('Toggle Galaxy off');
            socket.emit('hideProjection', { value: 'cyk4C8A%uVeVRqvn' });
        } catch (error) {
            onError(error);
        }
    };

    offWall = (onError) => {
        try {
            console.log('Toggle Wall off');
            socket.emit('hideWall', { value: 'Bi#bYU@Gcv3Q0LZ@' });
        } catch (error) {
            onError(error);
        }
    };
}

const dashboardServiceInstance = new DashboardService();
export default dashboardServiceInstance;
