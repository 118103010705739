import axiosInstance from '../../services/axios-service';

const base_url = '/v1/media';

class MediaService {
    createMedia(newMedia) {
        return axiosInstance.post(`${base_url}`, newMedia, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    updateMedia(mediaId, mediaCaption) {
        return axiosInstance.put(`${base_url}/${mediaId}`, { mediaCaption });
    }
    deleteMedia(mediaId, storyId) {
        return axiosInstance.delete(`${base_url}/${mediaId}/${storyId}`);
    }
}

const mediaServiceInstance = new MediaService();
export default mediaServiceInstance;
