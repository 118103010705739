import React, { useState, useEffect } from 'react';
import CategoryService from './CategoryService';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik'; // Import Formik-related components

const NewCategory = ({ show, onHide, _category }) => {
    const [category, setCategory] = useState({
        categoryName: ''
    });
    const [isNew, setIsNew] = useState(true);

    useEffect(() => {
        console.log(_category);
        if (_category._id) {
            setCategory(_category);
            setIsNew(false);
        } else {
            setIsNew(true);
        }
    }, [_category]);

    const submitHandler = async (values, { resetForm }) => {
        try {
            if (isNew) {
                await CategoryService.createCategory(values);
            } else {
                await CategoryService.updateCategory(values);
            }
        } catch (error) {
            console.log(error);
        }
        onHide();
        resetForm();
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Formik initialValues={category} onSubmit={submitHandler} enableReinitialize>
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>{isNew ? 'New' : 'Update'} Category</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label htmlFor="categoryName">Category Name</label>
                            <Field
                                type="text"
                                className="form-control"
                                name="categoryName"
                                required
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" type="submit">
                                {isNew ? 'Create' : 'Update'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

NewCategory.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    _category: PropTypes.object.isRequired
};

export default NewCategory;
