import React, { useState, useEffect, useMemo } from 'react';
import StoryService from './StoryService';
import CategoriesService from '../category/CategoryService';
import { Col, Row, Table, Form, Button, InputGroup } from 'react-bootstrap';
import NewStory from './NewStory';
import ConfirmDialog from '../shared/confirmDialog/ConfirmDialog';
import { FaCheck, FaTimes } from 'react-icons/fa';
import {
    DatatableWrapper,
    Filter as UnmemoizedFilter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import MediaOverlay from '../shared/mediaOverlay/MediaOverlay';

function Story() {
    const [stories, setStories] = useState([]);

    // const [expandedRow, setExpandedRow] = useState(null);

    const [selectedStory, setSelectedStory] = useState({});

    const [showNewStory, setShowNewStory] = useState(false);

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmDialogProps, setConfirmDialogProps] = useState({});

    const [filterText, setFilterText] = useState('');

    // const onRowClick = (row) => {
    //     // console.log(row);
    //     setExpandedRow(expandedRow === row._id ? null : row._id);
    // };

    const [mediaView, setMediaView] = useState({});
    const [isMediaVisible, setIsMediaVisible] = useState(false);

    const handleMediaViewClose = () => {
        setIsMediaVisible(false);
        setMediaView({});
    };

    const handleMediaViewOpen = (media) => {
        console.log(media);
        setIsMediaVisible(true);
        if (media.mediaType.includes('/')) {
            media.mediaType = media.mediaType.split('/')[0];
        }
        setMediaView(media);
    };

    const handleCloseNewStory = () => {
        setSelectedStory({});
        GetData();
        setShowNewStory(false);
    };

    const handleShowNewStory = (_category = {}) => {
        setSelectedStory(_category);
        setShowNewStory(true);
    };

    const storyPillarMapping = {
        Donor: 'Donor stories',
        Fundraising: 'Fundraising stories',
        Research: 'Impact stories',
        Patient: 'Our supporters'
    };

    const GetData = async () => {
        try {
            // Fetch categories and stories concurrently
            const [catResults, storResults] = await Promise.all([
                CategoriesService.getCategories(),
                StoryService.getStories()
            ]);

            const updatedStories = storResults.data.data.map((story) => {
                story.categories = getCategoryNames(story._id, catResults.data.data);
                return story;
            });

            setStories(updatedStories);
        } catch (error) {
            console.error('Error fetching categories and stories:', error);
        }
    };

    const getCategoryNames = (storyId, categories) => {
        const relatedCategories = categories.filter((category) =>
            category.categoryListings.includes(storyId)
        );
        return relatedCategories.map((category) => category.categoryName).join('\n');
    };

    useEffect(() => {
        GetData();
    }, []);

    const header = useMemo(
        () => [
            { title: 'Created', prop: 'storyCreatedAt', isSortable: true },
            { title: 'Story Title', prop: 'storyHeaderCopy', isSortable: true },
            { title: 'Story Subtitle', prop: 'storySubtitleCopy', isSortable: true },
            // {
            //     title: 'Main Body Copy',
            //     prop: 'storyMainCopyHTML'
            // },
            {
                title: 'Main Body Copy',
                prop: 'storyMainCopy'
            },
            {
                title: 'Featured',
                prop: 'storyFeatured',
                isSortable: true,
                cell: (row) => (
                    <div>
                        {row.storyFeatured ? <FaCheck color="green" /> : <FaTimes color="red" />}
                    </div>
                )
            },
            {
                title: 'Video Attached',
                prop: 'storyMedia',
                isSortable: true
                // cell: (row) => (
                //     <div>
                //         {row.storyMedia.length > 0 ? (
                //             <FaCheck color="green" />
                //         ) : (
                //             <FaTimes color="red" />
                //         )}
                //     </div>
                // )
            },
            {
                title: 'Pillar',
                prop: 'storyPillar',
                isSortable: true
            },
            {
                title: 'Manage',
                prop: 'manage'
            }
        ],
        []
    );

    const filteredStories = useMemo(() => {
        if (filterText === '') return stories;

        const filterTerms = filterText
            .split(',')
            .map((term) => term.trim().toLowerCase())
            .filter((term) => term !== '');

        return stories.filter((story) => {
            const storyheader = story.storyHeaderCopy.toLowerCase();
            const pillars = story.storyPillar.toLowerCase();

            return filterTerms.some((term) => storyheader.includes(term) || pillars.includes(term));
        });
    }, [stories, filterText]);

    const body = useMemo(
        () =>
            filteredStories.map((story) => {
                const formattedDate = new Date(story.storyCreatedAt).toLocaleString();
                return {
                    _id: story._id,
                    storyCreatedAt: formattedDate,
                    storyHeaderCopy: story.storyHeaderCopy,
                    storySubtitleCopy: story.storySubtitleCopy,
                    // storyMainCopyHTML: (
                    //     <div
                    //         className={`width-800 mainCopy ${
                    //             expandedRow === story._id ? '' : 'collapsed'
                    //         }`}
                    //         onClick={() => {
                    //             console.log('setbody');
                    //             onRowClick(story);
                    //         }}
                    //         dangerouslySetInnerHTML={{
                    //             __html: story.storyMainCopyHTML || story.storyMainCopy
                    //         }}
                    //     >
                    //         {/* {story.storyMainCopyHTML || story.storyMainCopy} */}
                    //     </div>
                    // ),
                    // storyMainCopy: story.storyTextMedia.length,
                    storyMainCopy: (
                        <div className="d-flex flex-row flex-wrap ">
                            {story.storyTextMedia &&
                                story.storyTextMedia.map((media, index) => (
                                    <div
                                        key={index}
                                        className="d-flex align-items-center justify-content-center m-1"
                                    >
                                        <div className="border">
                                            <img
                                                src={media.mediaFileName}
                                                alt="preview"
                                                width="100"
                                                onClick={() =>
                                                    handleMediaViewOpen({
                                                        mediaType: media.mediaType,
                                                        mediaFileName: media.mediaFileName
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ),
                    storyFeatured: story.storyFeatured,
                    storyMedia:
                        story.storyMedia.length > 0 ? (
                            <video
                                src={story.storyMedia[0].mediaFileName}
                                width="150"
                                onClick={() =>
                                    handleMediaViewOpen({
                                        mediaType: story.storyMedia[0].mediaType,
                                        mediaFileName: story.storyMedia[0].mediaFileName
                                    })
                                }
                            />
                        ) : null,
                    storyPillar: storyPillarMapping[story.storyPillar] || story.storyPillar,
                    manage: (
                        <>
                            <Button
                                type="button"
                                className="m-1"
                                variant="primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleShowNewStory(story);
                                }}
                            >
                                Edit
                            </Button>
                            <Button
                                type="button"
                                className="m-1"
                                variant="danger"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    openConfirmDialog(
                                        'Delete Story',
                                        'Are you sure you want to delete this story? This action is irreversible.',
                                        deleteHandler,
                                        story,
                                        { variant: 'danger', text: 'Delete' }
                                    );
                                }}
                            >
                                Delete
                            </Button>
                        </>
                    )
                };
            }),
        // [filteredStories, expandedRow]
        [filteredStories]
    );

    const deleteHandler = async (story) => {
        let isSubscribed = true;
        if (isSubscribed) {
            await StoryService.deleteStory(story._id);
            setStories(stories.filter((item) => item.id !== story._id));
            GetData();
        }
        return () => (isSubscribed = false);
    };

    const openConfirmDialog = (title, message, action, data, button) => {
        setConfirmDialogProps({ title, message, action, data, button });
        setShowConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setShowConfirmDialog(false);
    };

    const Filter = React.memo(UnmemoizedFilter);

    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Stories</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <Button
                        type="button"
                        className="m-1"
                        variant="primary"
                        onClick={() => handleShowNewStory()}
                    >
                        New Story
                    </Button>
                </div>
            </div>
            <InputGroup>
                <Form.Control
                    type="text"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    placeholder="Search..."
                />
                {filterText && (
                    <Button variant="outline-secondary" onClick={() => setFilterText('')}>
                        Clear
                    </Button>
                )}
            </InputGroup>

            <DatatableWrapper
                body={body}
                headers={header}
                paginationOptionsProps={{
                    initialState: {
                        rowsPerPage: 20,
                        options: [10, 20, 30, 40, 50]
                    }
                }}
                sortProps={{
                    initialState: {
                        order: 'desc',
                        prop: ''
                    }
                }}
            >
                <Row className="mb-4">
                    <Col
                        xs={12}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >
                        <Filter />
                    </Col>
                </Row>
                <Table>
                    <TableHeader />
                    <TableBody
                    // onRowClick={(row) => {
                    //     console.log('body');
                    //     onRowClick(row);
                    // }}
                    />
                </Table>
                <Row className="mb-4">
                    <p>
                        Total Stories: {stories.length}{' '}
                        {stories.length != filteredStories.length &&
                            `(showing ${filteredStories.length})`}
                    </p>
                    <Col
                        xs={12}
                        sm={6}
                        lg={6}
                        className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"
                    >
                        <PaginationOptions />
                    </Col>
                    <Col
                        xs={12}
                        sm={6}
                        lg={6}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >
                        <Pagination />
                    </Col>
                </Row>
            </DatatableWrapper>
            <NewStory
                show={showNewStory}
                onHide={handleCloseNewStory}
                _story={selectedStory}
            ></NewStory>
            <ConfirmDialog
                show={showConfirmDialog}
                onHide={handleCloseConfirmDialog}
                {...confirmDialogProps}
            ></ConfirmDialog>
            {isMediaVisible && <MediaOverlay media={mediaView} onClose={handleMediaViewClose} />}
        </div>
    );
}

export default Story;
