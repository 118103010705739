import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom';

import Alerter from './components/shared/alert/Alert';

import Dashboard from './components/dashboard/Dashboard';
import Category from './components/category/Category';
import Story from './components/story/Story';
import PlanetConfig from './components/planetConfig/PlanetConfig';
import Listing from './components/listing/Listing';
// import Users from './components/users/Users';

import LoginPage from './components/auth/Login';
import AWSService from './services/aws-service';
import { Button } from 'react-bootstrap';

function Root() {
    const [accessToken, setAccessToken] = useState();
    const [idToken, setIdToken] = useState();

    const logOut = async () => {
        AWSService.signOut(() => {
            console.log('Logged Out!');
        });
    };

    AWSService.getAccessToken().subscribe((res) => {
        if (accessToken !== res) {
            setAccessToken(res);
        }
    });

    AWSService.getIdToken().subscribe((res) => {
        if (idToken !== res) {
            setIdToken(res);
        }
    });

    return (
        <div className="container">
            <Alerter />
            {!accessToken ? (
                <div>
                    <header>
                        <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
                            <a
                                href="/"
                                className="d-flex align-items-center text-dark text-decoration-none"
                            >
                                <span className="fs-4">DONOR WALL CMS</span>
                            </a>
                        </div>
                    </header>
                    <main>
                        <Routes>
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="*" element={<Navigate to="/login" />} />
                        </Routes>
                    </main>
                </div>
            ) : (
                <div>
                    <header>
                        <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
                            <a
                                href="/"
                                className="d-flex align-items-center text-dark text-decoration-none"
                            >
                                <span className="fs-4">DONOR WALL CMS</span>
                            </a>
                            <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
                                <Link
                                    className="me-3 py-2 text-dark text-decoration primary"
                                    to="/dashboard"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    className="me-3 py-2 text-dark text-decoration"
                                    to="/categories"
                                >
                                    Recognition Categories
                                </Link>
                                <Link
                                    className="me-3 py-2 text-dark text-decoration"
                                    to="/listings"
                                >
                                    Honour Board Listings
                                </Link>
                                <Link
                                    className="me-3 py-2 text-dark text-decoration"
                                    to="/planet-config"
                                >
                                    Planet Configurator
                                </Link>
                                <Link className="me-3 py-2 text-dark text-decoration" to="/stories">
                                    Stories
                                </Link>
                            </nav>
                            <Button className="m-1" onClick={logOut}>
                                Log Out
                            </Button>
                        </div>
                    </header>

                    <main>
                        <Routes>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/planet-config" element={<PlanetConfig />} />
                            <Route path="/listings" element={<Listing />} />
                            <Route path="/categories" element={<Category />} />
                            <Route path="/stories" element={<Story />} />
                            {/* <Route path="/users" element={<Users />} /> */}
                            <Route path="*" element={<Navigate to="/dashboard" />} />
                        </Routes>
                    </main>
                </div>
            )}
        </div>
    );
}

function App() {
    const refreshSession = async () => {
        if (localStorage.getItem('username') && localStorage.getItem('refreshToken')) {
            try {
                // eslint-disable-next-line no-unused-vars
                const loginResult = await AWSService.signInWithRefresh(
                    localStorage.getItem('refreshToken')
                );
            } catch (error) {
                console.log(error);
                localStorage.clear('username');
                localStorage.clear('refreshToken');
            }
        }
    };
    refreshSession();

    return (
        <BrowserRouter>
            <Root />
        </BrowserRouter>
    );
}
export default App;
