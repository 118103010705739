import axiosInstance from '../../services/axios-service';

const category_base_url = '/v1/category';
const bulk_base_url = '/v1/bulkupload';
const planet_base_url = '/v1/planets';
class CategoriesService {
    getCategories() {
        return axiosInstance.get(category_base_url);
    }
    createCategory(category) {
        return axiosInstance.post(category_base_url, category);
    }
    getCategoryById(categoryId) {
        return axiosInstance.get(`${category_base_url}/${categoryId}`);
    }
    updateCategory(category) {
        return axiosInstance.put(`${category_base_url}/${category._id}`, category);
    }
    deleteCategory(category) {
        return axiosInstance.delete(`${category_base_url}/${category._id}`);
    }
    clearListings(category) {
        return axiosInstance.put(`${category_base_url}/clear/${category._id}`);
    }

    bulkUpload(categoryId, deleteAll, file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('categoryId', categoryId);
        formData.append('deleteAll', deleteAll);

        return axiosInstance.post(bulk_base_url, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
    }

    updatePlanets(planets) {
        return axiosInstance.post(planet_base_url, planets);
    }
}
const categoriesServiceInstance = new CategoriesService();
export default categoriesServiceInstance;
