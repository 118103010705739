import axiosInstance from '../../services/axios-service';

const base_url = '/v1/story';

class StoryService {
    getStories() {
        return axiosInstance.get(base_url);
    }
    createStory(story) {
        return axiosInstance.post(base_url, story);
    }
    getStoryById(storyId) {
        return axiosInstance.get(`${base_url}/${storyId}`);
    }
    updateStory(story) {
        return axiosInstance.put(`${base_url}/${story._id}`, story);
    }
    deleteStory(storyId) {
        return axiosInstance.delete(`${base_url}/${storyId}`);
    }
}

const storyServiceInstance = new StoryService();
export default storyServiceInstance;
