import React, { useCallback, useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { BsDownload } from 'react-icons/bs';
import { useDropzone } from 'react-dropzone';
import { ExportToCsv } from 'export-to-csv';
import Papa from 'papaparse';

import CategoryService from './CategoryService';

const BulkUploadModal = ({ show, onHide, _category }) => {
    const [category, setCategory] = useState('');
    const [file, setFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [lineCount, setLineCount] = useState(0);
    const [deleteAll, setDeleteAll] = useState(false);

    const handleDeleteAllChange = (e) => {
        console.log(e);
        setDeleteAll(e.target.checked);
    };

    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        filename: 'bulk_upload_listings_template',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true
    };

    useEffect(() => {
        console.log(_category);
        setCategory(_category);

        return () => {
            setDeleteAll(false); // Reset deleteAll state when modal is closed
            setFile(null); // Reset file state when modal is closed
            setLineCount(0); // Reset lineCount state when modal is closed
        };
    }, [_category]);

    // Download handler for the template
    const handleDownloadTemplate = () => {
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv([
            {
                'Donor Name': '',
                'Name to Display': '',
                'Listing Subtitle': '',
                'Listing Donor Id': '',
                'Show on Stone (Y or N)': '',
                'Show on Event (Y or N)': ''
            }
        ]);
    };

    const countCsvLines = (file) => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                error: (err) => {
                    reject(err);
                },
                complete: (results) => {
                    // Filter out empty lines
                    const nonEmptyLines = results.data.filter((row) => {
                        // Check if all elements in the row are empty or just whitespace
                        return !row.every((cell) => !cell.trim());
                    });

                    // Subtract 1 to exclude header row
                    resolve(nonEmptyLines.length - 1);
                }
            });
        });
    };

    // Drag and drop file picker
    const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
        console.log(acceptedFiles);
        setFile(acceptedFiles[0]); // Update file state
        setErrorMessage(
            rejectedFiles.length > 0
                ? 'Invalid file extension provided. Please upload a CSV file.'
                : ''
        );

        try {
            const lineCount = await countCsvLines(acceptedFiles[0]);
            setLineCount(lineCount);
        } catch (err) {
            console.error('Error parsing CSV file:', err);
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: { 'text/csv': ['.csv'] },
        onDrop
    });

    const submitHandler = async (e) => {
        e.preventDefault();
        console.log(deleteAll);
        try {
            await CategoryService.bulkUpload(category._id, deleteAll, file);
        } catch (error) {
            console.log(error);
        }
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <form className="form-inline" onSubmit={submitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>Bulk Upload</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Uploading listings to {category.categoryName}</label>

                    <div className="mt-3">
                        <Button variant="link" onClick={handleDownloadTemplate}>
                            <span className="custom-mr-3">Download Bulk Upload Template</span>
                            <BsDownload size={20} />
                        </Button>
                    </div>

                    <div {...getRootProps()} className="dropzone mt-4">
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Drop the CSV file here...</p>
                        ) : (
                            <p>
                                Drag &apos;n&apos; drop a CSV file here, or click to select a file
                            </p>
                        )}
                    </div>
                    {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}
                    {file && <p className="mt-2">Selected file: {file.name}</p>}
                    {file && <p className="mt-2">File contains {lineCount} listings to upload.</p>}
                    <div className="form-check mt-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value={deleteAll}
                            onChange={handleDeleteAllChange}
                            id="deleteAllCheckbox"
                        />
                        <label className="form-check-label" htmlFor="deleteAllCheckbox">
                            Delete and Replace All
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        type="submit"
                        onClick={onHide}
                        disabled={!file || lineCount == 0}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

BulkUploadModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    _category: PropTypes.object.isRequired
};

export default BulkUploadModal;
