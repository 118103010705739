import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Alert } from 'react-bootstrap';
import AlertService from '../../../services/alert-service';

const propTypes = {
    id: PropTypes.string,
    fade: PropTypes.bool
};

const defaultProps = {
    id: 'default-alert',
    fade: true
};

function Alerter({ id, fade }) {
    // const navigate = useNavigate();
    const location = useLocation();
    const [alerts, setAlerts] = useState([]);

    const removeAlert = useCallback(
        (alert) => {
            // console.log('remove');
            if (fade) {
                // fade out alert
                const alertWithFade = { ...alert, fade: true };
                setAlerts((alerts) => alerts.map((x) => (x === alert ? alertWithFade : x)));

                // remove alert after faded out
                setTimeout(() => {
                    setAlerts((alerts) => alerts.filter((x) => x !== alertWithFade));
                }, 250);
            } else {
                // remove alert
                setAlerts((alerts) => alerts.filter((x) => x !== alert));
            }
        },
        [fade]
    );

    useEffect(() => {
        // console.log('location: ', location);
        setAlerts((alerts) => {
            // console.log(alerts);
            const filteredAlerts = alerts.filter((x) => x.keepAfterRouteChange);

            filteredAlerts.forEach((x) => delete x.keepAfterRouteChange);
            // console.log(filteredAlerts);
            return filteredAlerts;
        });
    }, [location]);

    useEffect(() => {
        // console.log('asasd');
        // subscribe to new alert notifications
        const subscription = AlertService.onAlert(id).subscribe((alert) => {
            // clear alerts when an empty alert is received
            if (!alert.message) {
                setAlerts([]);
            } else {
                // add alert to array
                setAlerts((alerts) => [...alerts, alert]);

                // auto close alert if required
                if (alert.autoClose) {
                    setTimeout(() => removeAlert(alert), 3000);
                }
            }
            // console.log(alert);
        });

        // clean up function that runs when the component unmounts
        return () => {
            subscription.unsubscribe();
        };
    }, [id, removeAlert]);

    if (!alerts.length) return null;

    return (
        <div className="container position-fixed">
            <div className="m-3">
                {alerts.map((alert, index) => (
                    <Alert key={alert.type + index} variant={alert.type.toLowerCase()}>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <span dangerouslySetInnerHTML={{ __html: alert.message }}></span>
                            <Button
                                className="m-1"
                                variant={'outline-' + alert.type.toLowerCase()}
                                onClick={() => removeAlert(alert)}
                            >
                                X
                            </Button>
                        </div>
                    </Alert>
                ))}
            </div>
        </div>
    );
}

Alerter.propTypes = propTypes;
Alerter.defaultProps = defaultProps;
export default Alerter;
