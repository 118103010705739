import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

const defaultId = 'default-alert';

export class AlertService {
    constructor() {
        this.alertSubject = new Subject();
    }

    static AlertType = {
        Success: 'Success',
        Error: 'Danger',
        Info: 'Info',
        Warning: 'Warning'
    };

    onAlert = (id = defaultId) => {
        return this.alertSubject.asObservable().pipe(filter((x) => x && x.id === id));
    };

    success = (message, options) => {
        this.alert({ ...options, type: AlertService.AlertType.Success, message });
    };

    error = (message, options) => {
        this.alert({ ...options, type: AlertService.AlertType.Error, message });
    };

    info = (message, options) => {
        this.alert({ ...options, type: AlertService.AlertType.Info, message });
    };

    warn = (message, options) => {
        this.alert({ ...options, type: AlertService.AlertType.Warning, message });
    };

    alert = (alert) => {
        alert.id = alert.id || defaultId;
        this.alertSubject.next(alert);
    };

    clear = (id = defaultId) => {
        this.alertSubject.next({ id });
    };
}

const alertServiceInstance = new AlertService();
export default alertServiceInstance;
