import React, { useState, useEffect } from 'react';
import ListingService from './ListingService';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Col, Form as BootstrapForm } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import Select from 'react-select';
import CategoriesService from '../category/CategoryService';

const NewListing = ({ show, onHide, _listing }) => {
    const emptyListing = {
        listingName: '',
        listingTitle: '',
        listingSubtitle: '',
        listingShowOnStone: false,
        listingEventEnabled: false,
        listingDonorId: ''
    };

    const [listing, setListing] = useState(emptyListing);

    const [isNew, setIsNew] = useState(true); // Add isNew state

    const [categories, setCategories] = useState([]); // Add categories state
    const [selectedCategories, setSelectedCategories] = useState([]); // Add selectedCategories state

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await CategoriesService.getCategories();
                const categoriesData = response.data.data.map((category) => {
                    const isSelected = category.categoryListings.some((id) => id === _listing._id);
                    return {
                        value: category._id,
                        label: category.categoryName,
                        isSelected: isSelected
                    };
                });

                const selected = categoriesData.filter((category) => category.isSelected);
                setSelectedCategories(selected);

                setCategories(categoriesData);
            } catch (error) {
                console.log(error);
            }
        };
        fetchCategories();

        if (_listing._id) {
            setListing(_listing);
            // setListingBody(_listing.listingMainCopy);
            setIsNew(false); // Update isNew state
        } else {
            setListing(emptyListing);
            // setListingBody('');
            setIsNew(true); // Reset isNew state
        }
    }, [_listing]);

    const onSubmit = async (values) => {
        values.categories = selectedCategories.map((cat) => cat.value);
        try {
            if (isNew) {
                await ListingService.createListing(values);
            } else {
                await ListingService.updateListing(values);
            }
        } catch (error) {
            console.log(error);
        }
        onHide();
    };

    return (
        <Modal size="lg" show={show} onHide={onHide} centered>
            <Formik initialValues={listing} onSubmit={onSubmit} enableReinitialize>
                {({ values, handleChange }) => (
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>{isNew ? 'New' : 'Update'} Listing</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <BootstrapForm.Group controlId="listingName">
                                        <BootstrapForm.Label>Donor Name *</BootstrapForm.Label>
                                        <Field
                                            type="text"
                                            name="listingName"
                                            className="form-control"
                                            required
                                        />
                                    </BootstrapForm.Group>
                                    <BootstrapForm.Group controlId="listingTitle">
                                        <BootstrapForm.Label>Name to Display *</BootstrapForm.Label>
                                        <Field
                                            type="text"
                                            name="listingTitle"
                                            className="form-control"
                                            required
                                        />
                                    </BootstrapForm.Group>
                                    <BootstrapForm.Group controlId="listingDonorId">
                                        <BootstrapForm.Label>Listing Donor Id</BootstrapForm.Label>
                                        <Field
                                            type="text"
                                            name="listingDonorId"
                                            className="form-control"
                                        />
                                    </BootstrapForm.Group>
                                    <BootstrapForm.Group controlId="listingSubtitle">
                                        <BootstrapForm.Label>
                                            Listing Subtitle (if applicable)
                                        </BootstrapForm.Label>
                                        <Field
                                            type="text"
                                            name="listingSubtitle"
                                            className="form-control"
                                        />
                                    </BootstrapForm.Group>
                                    <BootstrapForm.Group controlId="listingShowOnStone">
                                        <BootstrapForm.Check
                                            type="checkbox"
                                            label="Show on Stone *"
                                            name="listingShowOnStone"
                                            className="my-3"
                                            checked={values.listingShowOnStone}
                                            onChange={handleChange}
                                        />
                                    </BootstrapForm.Group>
                                    <BootstrapForm.Group controlId="listingEventEnabled">
                                        <BootstrapForm.Check
                                            type="checkbox"
                                            label="Enabled for Events"
                                            name="listingEventEnabled"
                                            className="my-3"
                                            checked={values.listingEventEnabled}
                                            onChange={handleChange}
                                        />
                                    </BootstrapForm.Group>
                                    <BootstrapForm.Group controlId="listingCategories">
                                        <BootstrapForm.Label>
                                            Recognition Category
                                        </BootstrapForm.Label>
                                        <Select
                                            isMulti
                                            name="listingCategories"
                                            options={categories}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            value={selectedCategories}
                                            onChange={(values) => setSelectedCategories(values)}
                                        />
                                    </BootstrapForm.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" type="submit">
                                {isNew ? 'Create' : 'Update'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

NewListing.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    _listing: PropTypes.object.isRequired
};

export default NewListing;
