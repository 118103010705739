import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const CategoryItem = ({ category, provided, innerRef }) => {
    return (
        <Card
            ref={innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="mb-2"
        >
            <Card.Body>{category.categoryName}</Card.Body>
        </Card>
    );
};

CategoryItem.propTypes = {
    category: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        categoryName: PropTypes.string.isRequired
    }).isRequired,
    provided: PropTypes.object.isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]).isRequired
};

CategoryItem.defaultProps = {
    category: {
        _id: '',
        categoryName: 'Default Category'
    }
};

export default CategoryItem;
