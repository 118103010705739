import React, { useState, useEffect } from 'react';
import CategoryService from './CategoryService';
import { Button } from 'react-bootstrap';

import ConfirmDialog from '../shared/confirmDialog/ConfirmDialog';

import BulkUploadModal from './BulkUpload';
import NewCategory from './NewCategory';

function Category() {
    const [categories, setCategories] = useState([]);
    const [search, setSearch] = useState('');

    const [selectedCategory, setSelectedCategory] = useState({});

    const [showNewCategory, setShowNewCategory] = useState(false);

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmDialogProps, setConfirmDialogProps] = useState({});

    const handleCloseNewCategory = () => {
        setSelectedCategory({});
        GetData();
        setShowNewCategory(false);
    };

    const handleShowNewCategory = (_category = {}) => {
        setSelectedCategory(_category);
        setShowNewCategory(true);
    };

    const [showBulkUpload, setShowBulkUpload] = useState(false);

    const handleCloseBulkUpload = () => {
        GetData();
        setShowBulkUpload(false);
    };

    const handleShowBulkUpload = (_category) => {
        setSelectedCategory(_category);
        setShowBulkUpload(true);
    };

    const openConfirmDialog = (title, message, action, data, button) => {
        setConfirmDialogProps({
            title,
            message,
            action,
            data,
            button
        });
        setShowConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setShowConfirmDialog(false);
    };

    const GetData = async () => {
        const results = await CategoryService.getCategories();
        console.log(results);
        setCategories(results.data.data);
    };

    useEffect(() => {
        GetData();
    }, []);

    const deleteHandler = async (id) => {
        await CategoryService.deleteCategory(id);
        GetData();
    };

    const clearStoriesHandler = async (_category) => {
        // console.log(_category.categoryListings.length);
        // _category.categoryListings = [];
        // console.log(_category.categoryListings.length);
        await CategoryService.clearListings(_category);
        GetData();
    };

    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Recognition Categories</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <Button
                        type="button"
                        className="m-1"
                        variant="primary"
                        onClick={() => handleShowNewCategory()}
                    >
                        New Category
                    </Button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search by title"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Listings</th>
                                <th>Manage Listings</th>
                                <th>Manage Categories</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories
                                .filter((item) =>
                                    item?.categoryName?.toLowerCase().includes(search.toLowerCase())
                                )
                                .map((category) => (
                                    <tr key={category._id}>
                                        <td className="align-middle">{category.categoryName}</td>
                                        <td className="align-middle">
                                            {category.categoryListings.length}
                                        </td>
                                        <td className="align-middle">
                                            <Button
                                                type="button"
                                                className="m-1"
                                                variant="danger"
                                                onClick={() =>
                                                    openConfirmDialog(
                                                        'Clear Listings',
                                                        'Are you sure you want to clear all listings in this category?',
                                                        clearStoriesHandler,
                                                        category,
                                                        { variant: 'danger', text: 'Clear' }
                                                    )
                                                }
                                            >
                                                Clear Listings
                                            </Button>
                                            <Button
                                                type="button"
                                                className="m-1"
                                                variant="primary"
                                                onClick={() => handleShowBulkUpload(category)}
                                            >
                                                Bulk Upload
                                            </Button>
                                        </td>
                                        <td className="align-middle">
                                            <Button
                                                type="button"
                                                className="m-1"
                                                variant="primary"
                                                onClick={() => handleShowNewCategory(category)}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                type="button"
                                                className="m-1"
                                                variant="danger"
                                                onClick={() =>
                                                    openConfirmDialog(
                                                        'Delete Category',
                                                        'Are you sure you want to delete this category? This action is irreversible.',
                                                        deleteHandler,
                                                        category,
                                                        { variant: 'danger', text: 'Delete' }
                                                    )
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <NewCategory
                show={showNewCategory}
                onHide={handleCloseNewCategory}
                _category={selectedCategory}
            ></NewCategory>
            <BulkUploadModal
                show={showBulkUpload}
                onHide={handleCloseBulkUpload}
                _category={selectedCategory}
            ></BulkUploadModal>
            <ConfirmDialog
                show={showConfirmDialog}
                onHide={handleCloseConfirmDialog}
                {...confirmDialogProps}
            ></ConfirmDialog>
        </div>
    );
}

export default Category;
