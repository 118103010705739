import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Droppable } from 'react-beautiful-dnd';

const DropZone = ({ zoneName, droppableId, children, maxItems }) => {
    const isFull = maxItems && React.Children.count(children) >= maxItems;

    return (
        <Card
            className="mb-4"
            style={{
                minHeight: '200px',
                borderColor: isFull ? 'red' : undefined,
                borderWidth: isFull ? 2 : 1
            }}
        >
            <Card.Header>
                <h3>{zoneName}</h3>
            </Card.Header>
            <Droppable droppableId={droppableId}>
                {(provided, snapshot) => (
                    <Card.Body
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                            backgroundColor: snapshot.isDraggingOver ? '#e0e0e0' : 'white',
                            borderColor: isFull && snapshot.isDraggingOver ? 'red' : undefined
                        }}
                    >
                        {children}
                        {provided.placeholder}
                    </Card.Body>
                )}
            </Droppable>
        </Card>
    );
};

DropZone.propTypes = {
    zoneName: PropTypes.string.isRequired,
    droppableId: PropTypes.string.isRequired,
    children: PropTypes.node,
    maxItems: PropTypes.number
};

DropZone.defaultProps = {
    children: null,
    maxItems: undefined
};

export default DropZone;
