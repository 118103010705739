import React, { useState } from 'react';
import PropTypes from 'prop-types';

const MediaComponent = ({ media, onClose }) => {
    const { mediaType, mediaFileName } = media;

    const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => {
        setIsVisible(false);
        onClose();
    };

    if (!isVisible) {
        return null;
    }

    const handleMediaClick = (event) => {
        // Stop propagation to prevent the overlay's onClick handler from being called
        event.stopPropagation();
    };

    const mediaElement =
        mediaType === 'image' ? (
            <img
                src={mediaFileName}
                alt="Uploaded Media"
                className="media-element"
                onClick={handleMediaClick}
            />
        ) : mediaType === 'video' ? (
            <video controls className="media-element" onClick={handleMediaClick}>
                <source src={mediaFileName} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        ) : (
            <p>Invalid media type</p>
        );

    return (
        <div className="media-overlay" onClick={handleClose}>
            <button onClick={handleClose} className="close-button">
                &times;
            </button>
            {mediaElement}
        </div>
    );
};

MediaComponent.propTypes = {
    media: PropTypes.shape({
        mediaType: PropTypes.oneOf(['image', 'video']).isRequired,
        mediaFileName: PropTypes.string.isRequired
    }).isRequired,
    onClose: PropTypes.func.isRequired
};

export default MediaComponent;
