import React, { useState, useEffect } from 'react';

import DashboardService from './DashboardService';
import UpdateMessageModal from './UpdateMessage';
import ConfirmDialog from '../shared/confirmDialog/ConfirmDialog';
import TimePicker from 'react-bootstrap-time-picker';
import { Button, Table } from 'react-bootstrap';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const useModalState = (initialValue = false) => {
    const [show, setShow] = useState(initialValue);
    const openModal = () => setShow(true);
    const closeModal = () => setShow(false);
    return [show, openModal, closeModal];
};

const InfoPanelRow = ({ title, message, onEditClick }) => (
    <tr>
        <td className="align-middle">{title}</td>
        <td className="align-middle" style={{ whiteSpace: 'pre-wrap' }}>
            {message}
        </td>
        <td className="align-middle">
            <Button className="m-1" onClick={onEditClick}>
                Edit
            </Button>
        </td>
    </tr>
);

InfoPanelRow.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onEditClick: PropTypes.func.isRequired
};

function Dashboard() {
    const [VWMessage, setVWMessage] = useState('');
    const [ProjMessage, setProjMessage] = useState('');
    const [IPLeftHeadMessage, setIPLeftHeadMessage] = useState('');
    const [IPLeftMessage, setIPLeftMessage] = useState('');
    const [IPLeftDotsMessage, setIPLeftDotsMessage] = useState('');
    const [IPRightHeadMessage, setIPRightHeadMessage] = useState('');
    const [IPRightMessage, setIPRightMessage] = useState('');
    const [IPRightSupportMessage, setIPRightSupportMessage] = useState('');
    const [IPRightSupportUrlMessage, setIPRightSupportUrlMessage] = useState('');

    const [eventMode, setEventMode] = useState(false);

    const [showUpdateVWMessage, openUpdateVWMessage, closeUpdateVWMessage] = useModalState();
    const [showUpdateProjMessage, openUpdateProjMessage, closeUpdateProjMessage] = useModalState();
    const [showUpdateIPLeftHeadMessage, openUpdateIPLeftHeadMessage, closeUpdateIPLeftHeadMessage] =
        useModalState();
    const [showUpdateIPLeftMessage, openUpdateIPLeftMessage, closeUpdateIPLeftMessage] =
        useModalState();
    const [showUpdateIPLeftDotsMessage, openUpdateIPLeftDotsMessage, closeUpdateIPLeftDotsMessage] =
        useModalState();
    const [
        showUpdateIPRightHeadMessage,
        openUpdateIPRightHeadMessage,
        closeUpdateIPRightHeadMessage
    ] = useModalState();
    const [showUpdateIPRightMessage, openUpdateIPRightMessage, closeUpdateIPRightMessage] =
        useModalState();
    const [
        showUpdateIPRightSupportMessage,
        openUpdateIPRightSupportMessage,
        closeUpdateIPRightSupportMessage
    ] = useModalState();
    const [
        showUpdateIPRightSupportUrlMessage,
        openUpdateIPRightSupportUrlMessage,
        closeUpdateIPRightSupportUrlMessage
    ] = useModalState();

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmDialogProps, setConfirmDialogProps] = useState({});

    const [data, setData] = useState([
        { endpoint: 'API', uri: 'api.pmcf.au', status: 'inactive' },
        { endpoint: 'CMS', uri: 'cms.pmcf.au', status: 'active' },
        { endpoint: 'App', uri: 'app.pmcf.au', status: 'inactive' }
    ]);

    const fetchCurrentSchedule = async () => {
        try {
            const schedule = await DashboardService.getSchedule();

            // Convert UTC time to Melbourne time and extract hours and minutes
            console.log(schedule.morningTime);
            console.log(schedule.eveningTime);
            const morningTimeMelbourne = moment(schedule.morningTime)
                .tz('Australia/Melbourne')
                .format('HH:mm');
            const eveningTimeMelbourne = moment(schedule.eveningTime)
                .tz('Australia/Melbourne')
                .format('HH:mm');
            console.log(morningTimeMelbourne);
            console.log(eveningTimeMelbourne);
            setMorningTime(morningTimeMelbourne);
            setEveningTime(eveningTimeMelbourne);
        } catch (error) {
            console.error('Error fetching schedule:', error);
        }
    };

    const getData = async () => {
        const msg = await DashboardService.getDashboard();
        console.log(msg);

        setVWMessage(msg.data.data.dashboardWallMessage);
        setProjMessage(msg.data.data.dashboardProjectionMessage);
        // const infoPanel = msg.data.data.infoPanel;
        setIPLeftHeadMessage(msg.data.data.dashboardInfoPanelLeftHeadMessage);
        setIPLeftMessage(msg.data.data.dashboardInfoPanelLeftMessage);
        setIPLeftDotsMessage(msg.data.data.dashboardInfoPanelLeftDotsMessage);
        setIPRightHeadMessage(msg.data.data.dashboardInfoPanelRightHeadMessage);
        setIPRightMessage(msg.data.data.dashboardInfoPanelRightMessage);
        setIPRightSupportMessage(msg.data.data.dashboardInfoPanelRightSupportMessage);
        setIPRightSupportUrlMessage(msg.data.data.dashboardInfoPanelRightSupportUrlMessage);

        setData([
            { endpoint: 'API', uri: 'api.pmcf.au', status: msg.data.apiStatus },
            { endpoint: 'CMS', uri: 'cms.pmcf.au', status: 'active' },
            { endpoint: 'App', uri: 'app.pmcf.au', status: msg.data.appStatus }
        ]);
        setEventMode(msg.data.eventMode);
    };

    const [morningTime, setMorningTime] = useState('06:00');
    const [eveningTime, setEveningTime] = useState('19:00');

    const convertTimeToCronFormat = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return `0 ${minutes} ${hours} * * *`;
    };

    const convertSecondsToHHMM = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds); // specify value for seconds here
        return date.toISOString().substr(11, 5);
    };

    const handleScheduleUpdate = async () => {
        try {
            console.log(morningTime);
            console.log(eveningTime);
            const formattedMorningTime = convertTimeToCronFormat(morningTime);
            const formattedEveningTime = convertTimeToCronFormat(eveningTime);

            console.log(formattedMorningTime);
            console.log(formattedEveningTime);

            await DashboardService.updateSchedule(formattedMorningTime, formattedEveningTime);
            alert('Schedule updated successfully!');
        } catch (error) {
            alert('Failed to update schedule.');
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        getData();
        fetchCurrentSchedule();
    }, []);

    const openConfirmDialog = (title, message, action) => {
        setConfirmDialogProps({
            title,
            message,
            action,
            button: { variant: 'primary', text: 'Yes' }
        });
        setShowConfirmDialog(true);
    };
    const handleCloseConfirmDialog = () => {
        setShowConfirmDialog(false);
    };

    const updateWall = () => {
        DashboardService.updateWall();
    };

    const galaxyOff = () => {
        DashboardService.offGalaxy();
    };

    const galaxyOn = () => {
        DashboardService.onGalaxy();
    };

    const wallOff = () => {
        DashboardService.offWall();
    };

    const wallOn = () => {
        DashboardService.onWall();
    };

    const toggleEventMode = async () => {
        console.log(eventMode);
        try {
            const values = { dashboardEventEnabled: !eventMode };
            await DashboardService.updateDashboard(values);
            setEventMode(!eventMode); // Assuming you have a method to set eventMode state
        } catch (error) {
            // Handle the error appropriately
            console.error('Failed to toggle event mode: ', error);
        }
    };

    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Dashboard</h1>
            </div>
            <header className="h5">System Information</header>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Endpoint</th>
                        <th>URI</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td className="align-middle">{item.endpoint}</td>
                            <td className="align-middle">{item.uri}</td>
                            <td
                                className="align-middle"
                                style={{
                                    color: item.status === 'active' ? 'green' : 'red'
                                }}
                            >
                                {item.status}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <header className="h5">Show/Hide Activations</header>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Activation</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="align-middle">Donor Wall</td>
                        <td className="align-middle">
                            <Button
                                className="m-1"
                                onClick={() =>
                                    openConfirmDialog(
                                        'Update Wall',
                                        'Are you sure you want to update the Supporter Wall?',
                                        updateWall
                                    )
                                }
                            >
                                Update Wall
                            </Button>
                            <Button
                                className="m-1"
                                onClick={() =>
                                    openConfirmDialog(
                                        'Turn Wall On',
                                        'Are you sure you want to turn the Supporter Wall on?',
                                        wallOn
                                    )
                                }
                            >
                                Wall On
                            </Button>
                            <Button
                                className="m-1"
                                onClick={() =>
                                    openConfirmDialog(
                                        'Turn Wall Off',
                                        'Are you sure you want to turn the Supporter Wall off?',
                                        wallOff
                                    )
                                }
                            >
                                Wall Off
                            </Button>
                        </td>
                    </tr>
                    <tr>
                        <td className="align-middle">Projection</td>
                        <td className="align-middle">
                            <Button
                                className="m-1"
                                onClick={() =>
                                    openConfirmDialog(
                                        'Turn Galaxy Projection On',
                                        'Are you sure you want to turn the Galaxy Projection on?',
                                        galaxyOn
                                    )
                                }
                            >
                                Projection On
                            </Button>
                            <Button
                                className="m-1"
                                onClick={() =>
                                    openConfirmDialog(
                                        'Turn Galaxy Projection Off',
                                        'Are you sure you want to turn the Galaxy Projection off?',
                                        galaxyOff
                                    )
                                }
                            >
                                Projection Off
                            </Button>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <header className="h5">Event Mode</header>
            <Table striped bordered hover>
                <tbody>
                    <tr>
                        <td className="align-middle">
                            Event mode is currently:{' '}
                            <span className={eventMode ? 'text-success' : 'text-danger'}>
                                {eventMode ? 'Enabled' : 'Disabled'}
                            </span>
                        </td>
                        <td className="align-middle">
                            <Button className="m-1" onClick={toggleEventMode}>
                                {eventMode ? 'Disable Event Mode' : 'Enable Event Mode'}
                            </Button>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <header className="h5">Video Wall Text</header>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Section</th>
                        <th>Message</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <InfoPanelRow
                        title="Event Message"
                        message={VWMessage}
                        onEditClick={openUpdateVWMessage}
                    />
                    <InfoPanelRow
                        title="Projection Message"
                        message={ProjMessage}
                        onEditClick={openUpdateProjMessage}
                    />
                    <InfoPanelRow
                        title="Left Head Message"
                        message={IPLeftHeadMessage}
                        onEditClick={openUpdateIPLeftHeadMessage}
                    />
                    <InfoPanelRow
                        title="Left Message"
                        message={IPLeftMessage}
                        onEditClick={openUpdateIPLeftMessage}
                    />
                    <InfoPanelRow
                        title="Left Dots Message"
                        message={IPLeftDotsMessage}
                        onEditClick={openUpdateIPLeftDotsMessage}
                    />
                    <InfoPanelRow
                        title="Right Head Message"
                        message={IPRightHeadMessage}
                        onEditClick={openUpdateIPRightHeadMessage}
                    />
                    <InfoPanelRow
                        title="Right Message"
                        message={IPRightMessage}
                        onEditClick={openUpdateIPRightMessage}
                    />
                    <InfoPanelRow
                        title="Right Support Message"
                        message={IPRightSupportMessage}
                        onEditClick={openUpdateIPRightSupportMessage}
                    />
                    <InfoPanelRow
                        title="Right Support URL Message"
                        message={IPRightSupportUrlMessage}
                        onEditClick={openUpdateIPRightSupportUrlMessage}
                    />
                </tbody>
            </Table>
            <header className="h5">ON/OFF Schedule Manager</header>
            <Table striped bordered hover>
                <tbody>
                    <tr>
                        <td>Morning On Time</td>
                        <td>
                            <TimePicker
                                onChange={(seconds) =>
                                    setMorningTime(convertSecondsToHHMM(seconds))
                                }
                                initialValue={morningTime}
                                format={12}
                                start="00:00"
                                end="23:59"
                                step={30}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Evening Off Time</td>
                        <td>
                            <TimePicker
                                onChange={(seconds) =>
                                    setEveningTime(convertSecondsToHHMM(seconds))
                                }
                                initialValue={eveningTime}
                                format={12}
                                start="00:00"
                                end="23:59"
                                step={30}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <Button onClick={handleScheduleUpdate}>Update Schedule</Button>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <UpdateMessageModal
                show={showUpdateVWMessage}
                onHide={closeUpdateVWMessage}
                messageIn={VWMessage}
                keyIn={'dashboardWallMessage'}
                onUpdate={getData}
            ></UpdateMessageModal>
            <UpdateMessageModal
                show={showUpdateProjMessage}
                onHide={closeUpdateProjMessage}
                messageIn={ProjMessage}
                keyIn={'dashboardProjectionMessage'}
                onUpdate={getData}
            ></UpdateMessageModal>
            <UpdateMessageModal
                show={showUpdateIPLeftHeadMessage}
                onHide={closeUpdateIPLeftHeadMessage}
                messageIn={IPLeftHeadMessage}
                keyIn={'dashboardInfoPanelLeftHeadMessage'}
                onUpdate={getData}
            ></UpdateMessageModal>
            <UpdateMessageModal
                show={showUpdateIPLeftMessage}
                onHide={closeUpdateIPLeftMessage}
                messageIn={IPLeftMessage}
                keyIn={'dashboardInfoPanelLeftMessage'}
                onUpdate={getData}
            ></UpdateMessageModal>
            <UpdateMessageModal
                show={showUpdateIPLeftDotsMessage}
                onHide={closeUpdateIPLeftDotsMessage}
                messageIn={IPLeftDotsMessage}
                keyIn={'dashboardInfoPanelLeftDotsMessage'}
                onUpdate={getData}
            ></UpdateMessageModal>
            <UpdateMessageModal
                show={showUpdateIPRightHeadMessage}
                onHide={closeUpdateIPRightHeadMessage}
                messageIn={IPRightHeadMessage}
                keyIn={'dashboardInfoPanelRightHeadMessage'}
                onUpdate={getData}
            ></UpdateMessageModal>
            <UpdateMessageModal
                show={showUpdateIPRightMessage}
                onHide={closeUpdateIPRightMessage}
                messageIn={IPRightMessage}
                keyIn={'dashboardInfoPanelRightMessage'}
                onUpdate={getData}
            ></UpdateMessageModal>
            <UpdateMessageModal
                show={showUpdateIPRightSupportMessage}
                onHide={closeUpdateIPRightSupportMessage}
                messageIn={IPRightSupportMessage}
                keyIn={'dashboardInfoPanelRightSupportMessage'}
                onUpdate={getData}
            ></UpdateMessageModal>
            <UpdateMessageModal
                show={showUpdateIPRightSupportUrlMessage}
                onHide={closeUpdateIPRightSupportUrlMessage}
                messageIn={IPRightSupportUrlMessage}
                keyIn={'dashboardInfoPanelRightSupportUrlMessage'}
                onUpdate={getData}
            ></UpdateMessageModal>
            <ConfirmDialog
                show={showConfirmDialog}
                onHide={handleCloseConfirmDialog}
                {...confirmDialogProps}
            ></ConfirmDialog>
        </div>
    );
}

export default Dashboard;
