import React, { useState, useEffect, useMemo } from 'react';
import ListingService from './ListingService';
import CategoriesService from '../category/CategoryService';
import { Col, Row, Table, Form, Button, InputGroup } from 'react-bootstrap';
import NewListing from './NewListing';
import ConfirmDialog from '../shared/confirmDialog/ConfirmDialog';
import { FaCheck, FaTimes } from 'react-icons/fa';
import {
    DatatableWrapper,
    Filter as UnmemoizedFilter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';

function Listing() {
    const [listings, setListings] = useState([]);

    const [selectedListing, setSelectedListing] = useState({});

    const [showNewListing, setShowNewListing] = useState(false);

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmDialogProps, setConfirmDialogProps] = useState({});

    const [filterText, setFilterText] = useState('');

    const handleCloseNewListing = () => {
        setSelectedListing({});
        GetData();
        setShowNewListing(false);
    };

    const handleShowNewListing = (_category = {}) => {
        setSelectedListing(_category);
        setShowNewListing(true);
    };

    const GetData = async () => {
        try {
            // Fetch categories and listings concurrently
            const [catResults, listResults] = await Promise.all([
                CategoriesService.getCategories(),
                ListingService.getListings()
            ]);

            const updatedListings = listResults.data.data.map((listing) => {
                listing.categories = getCategoryNames(listing._id, catResults.data.data);
                return listing;
            });

            setListings(updatedListings);
        } catch (error) {
            console.error('Error fetching categories and listings:', error);
        }
    };

    const getCategoryNames = (listingId, categories) => {
        const relatedCategories = categories.filter((category) =>
            category.categoryListings.includes(listingId)
        );
        return relatedCategories.map((category) => category.categoryName).join('\n');
    };

    useEffect(() => {
        GetData();
    }, []);

    const header = useMemo(
        () => [
            { title: 'Created', prop: 'listingCreatedAt', isSortable: true },
            { title: 'Listing Donor Id', prop: 'listingDonorId', isSortable: true },
            { title: 'Donor Name', prop: 'listingName', isSortable: true },
            { title: 'Name to Display', prop: 'listingTitle', isSortable: true },
            { title: 'Listing Subtitle', prop: 'listingSubtitle', isSortable: true },
            {
                title: 'Event Enabled',
                prop: 'listingEventEnabled',
                isSortable: true,
                cell: (row) => (
                    <div>
                        {row.listingEventEnabled ? (
                            <FaCheck color="green" />
                        ) : (
                            <FaTimes color="red" />
                        )}
                    </div>
                )
            },
            {
                title: 'Show on Stone',
                prop: 'listingShowOnStone',
                isSortable: true,
                cell: (row) => (
                    <div>
                        {row.listingShowOnStone ? (
                            <FaCheck color="green" />
                        ) : (
                            <FaTimes color="red" />
                        )}
                    </div>
                )
            },
            {
                title: 'Recognition Category',
                prop: 'categories',
                isSortable: true
            },
            {
                title: 'Manage',
                prop: 'manage'
            }
        ],
        []
    );

    const filteredListings = useMemo(() => {
        if (filterText === '') return listings;

        const filterTerms = filterText
            .split(',')
            .map((term) => term.trim().toLowerCase())
            .filter((term) => term !== '');

        return listings.filter((listing) => {
            const listingName = listing.listingName.toLowerCase();
            const listingTitle = listing.listingTitle.toLowerCase();
            const categories = listing.categories.toLowerCase();
            const listingDonorIds = listing.listingDonorId.toLowerCase();
            return filterTerms.some(
                (term) =>
                    listingName.includes(term) ||
                    listingTitle.includes(term) ||
                    categories.includes(term) ||
                    listingDonorIds.includes(term)
            );
        });
    }, [listings, filterText]);

    const body = useMemo(
        () =>
            filteredListings.map((listing) => {
                const formattedDate = new Date(listing.listingCreatedAt).toLocaleString();
                return {
                    _id: listing._id,
                    listingCreatedAt: formattedDate,
                    listingDonorId: listing.listingDonorId
                        ? listing.listingDonorId
                        : 'not provided',
                    listingName: listing.listingName,
                    listingTitle: listing.listingTitle,
                    listingSubtitle: listing.listingSubtitle,
                    listingShowOnStone: listing.listingShowOnStone,
                    listingEventEnabled: listing.listingEventEnabled,
                    categories: listing.categories,
                    manage: (
                        <>
                            <Button
                                type="button"
                                className="m-1"
                                variant="primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleShowNewListing(listing);
                                }}
                            >
                                Edit
                            </Button>
                            <Button
                                type="button"
                                className="m-1"
                                variant="danger"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    openConfirmDialog(
                                        'Delete Listing',
                                        'Are you sure you want to delete this listing? This action is irreversible.',
                                        deleteHandler,
                                        listing,
                                        { variant: 'danger', text: 'Delete' }
                                    );
                                }}
                            >
                                Delete
                            </Button>
                        </>
                    )
                };
            }),
        [filteredListings]
    );

    const deleteHandler = async (listing) => {
        let isSubscribed = true;
        if (isSubscribed) {
            await ListingService.deleteListing(listing._id);
            setListings(listings.filter((item) => item.id !== listing._id));
            GetData();
        }
        return () => (isSubscribed = false);
    };

    const openConfirmDialog = (title, message, action, data, button) => {
        setConfirmDialogProps({ title, message, action, data, button });
        setShowConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setShowConfirmDialog(false);
    };

    const Filter = React.memo(UnmemoizedFilter);

    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Honour Board Listings</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <Button
                        type="button"
                        className="m-1"
                        variant="primary"
                        onClick={() => handleShowNewListing()}
                    >
                        New Listing
                    </Button>
                </div>
            </div>
            <InputGroup>
                <Form.Control
                    type="text"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    placeholder="Search..."
                />
                {filterText && (
                    <Button variant="outline-secondary" onClick={() => setFilterText('')}>
                        Clear
                    </Button>
                )}
            </InputGroup>

            <DatatableWrapper
                body={body}
                headers={header}
                paginationOptionsProps={{
                    initialState: {
                        rowsPerPage: 20,
                        options: [10, 20, 30, 40, 50]
                    }
                }}
                sortProps={{
                    initialState: {
                        order: 'desc',
                        prop: ''
                    }
                }}
            >
                <Row className="mb-4">
                    <Col
                        xs={12}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >
                        <Filter />
                    </Col>
                </Row>
                <Table>
                    <TableHeader />
                    <TableBody />
                </Table>
                <Row className="mb-4">
                    <p>
                        Total Listings: {listings.length}{' '}
                        {listings.length != filteredListings.length &&
                            `(showing ${filteredListings.length})`}
                    </p>
                    <Col
                        xs={12}
                        sm={6}
                        lg={6}
                        className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"
                    >
                        <PaginationOptions />
                    </Col>
                    <Col
                        xs={12}
                        sm={6}
                        lg={6}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >
                        <Pagination />
                    </Col>
                </Row>
            </DatatableWrapper>
            <NewListing
                show={showNewListing}
                onHide={handleCloseNewListing}
                _listing={selectedListing}
            ></NewListing>
            <ConfirmDialog
                show={showConfirmDialog}
                onHide={handleCloseConfirmDialog}
                {...confirmDialogProps}
            ></ConfirmDialog>
        </div>
    );
}

export default Listing;
