import React, { useState, useEffect } from 'react';
import StoryService from './StoryService';
import MediaService from './MediaService';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Col, Form as BootstrapForm } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { useDropzone } from 'react-dropzone';
// import { FaTrash, FaGoogleDrive } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa';
import Select from 'react-select';
import MediaOverlay from '../shared/mediaOverlay/MediaOverlay';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import ConfirmDialog from '../shared/confirmDialog/ConfirmDialog';

import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
GlobalWorkerOptions.workerSrc = pdfjsWorker;

const NewStory = ({ show, onHide, _story }) => {
    // const googleDocViewLink =
    //     'https://docs.google.com/document/d/1qlTT0GUMcIEgchEWKIICkPZe4sIn7AC7p_J8j-qY5SM';
    // const googleDocDownloadLink =
    // 'https://docs.google.com/document/d/1qlTT0GUMcIEgchEWKIICkPZe4sIn7AC7p_J8j-qY5SM/export?format=doc';

    const emptyStory = {
        storyHeaderCopy: '',
        storySubtitleCopy: '',
        storyPillar: '',
        storyFeatured: false
    };

    const [story, setStory] = useState(emptyStory);

    const [newMedia, setNewMedia] = useState([]);
    const [existingMedia, setExistingMedia] = useState([]);
    const [storyTextMedia, setStoryTextMedia] = useState([]);
    const [storyNewTextMedia, setStoryNewTextMedia] = useState([]);
    // const textMedia = [];

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmDialogProps, setConfirmDialogProps] = useState({});

    const [newCopy, setNewCopy] = useState();

    const [isNew, setIsNew] = useState(true); // Add isNew state

    // eslint-disable-next-line no-unused-vars
    const [storyPillars, setStoryPillars] = useState([
        { value: 'Donor', label: 'Donor stories' },
        { value: 'Patient', label: 'Our supporters' },
        { value: 'Research', label: 'Impact stories' },
        { value: 'Fundraising', label: 'Fundraising stories' }
    ]); // Add pillars state

    const [mediaView, setMediaView] = useState({});
    const [isMediaVisible, setIsMediaVisible] = useState(false);

    const handleMediaViewClose = () => {
        setIsMediaVisible(false);
        setMediaView({});
    };

    const handleMediaViewOpen = (media) => {
        console.log(media);
        setIsMediaVisible(true);
        if (media.mediaType.includes('/')) {
            media.mediaType = media.mediaType.split('/')[0];
        }
        setMediaView(media);
    };

    const onMediaDrop = (files) => {
        const url = URL.createObjectURL(files[0]);
        const newMediaObject = {
            file: files[0],
            url: url,
            mediaCaption: ''
        };

        setNewMedia(newMediaObject);
    };

    const onCopyDrop = (files) => {
        console.log(files[0]);
        const reader = new FileReader();
        reader.onload = async () => {
            // make the function async
            const arrayBuffer = reader.result;
            setNewCopy(arrayBuffer);
            await generateImagesFromPdf(arrayBuffer); // await the function
        };
        reader.readAsArrayBuffer(files[0]);
    };

    const removeNewMediaFile = () => {
        setNewMedia(null);
    };

    const removeExistingMediaFile = async () => {
        await MediaService.deleteMedia(existingMedia._id, story._id);
        setExistingMedia(null);
    };

    const { getRootProps: getMediaRootProps, getInputProps: getMediaInputProps } = useDropzone({
        // accept: { 'image/*': ['.jpg', '.png', '.gif'], 'video/*': ['.mp4', '.m4v', '.mov'] },
        accept: { 'video/*': ['.mp4', '.m4v', '.mov'] },
        // accept: ['image/*', 'video/*'],
        onDrop: onMediaDrop
    });

    const { getRootProps: getCopyRootProps, getInputProps: getCopyInputProps } = useDropzone({
        accept: { 'application/pdf': ['.pdf'] },
        // accept: ['application/pdf'],
        onDrop: onCopyDrop
    });

    useEffect(() => {
        console.log(_story);
        if (_story._id) {
            setStory(_story);
            setIsNew(false); // Update isNew state
            setExistingMedia(_story.storyMedia[0] || null); // Set existingMedia state
            setStoryTextMedia(_story.storyTextMedia || []);
            setStoryNewTextMedia([]);
            setNewMedia(null); // Reset newMedia state
            setNewCopy(); // Reset newCopy state
        } else {
            setStory(emptyStory);
            setIsNew(true); // Reset isNew state
            setExistingMedia(); // Reset existingMedia state
            setStoryTextMedia([]);
            setStoryNewTextMedia([]);
            setNewMedia(); // Reset existingMedia state
            setNewCopy(); // Reset newCopy state
        }
    }, [_story]);

    const processMedia = async (storyId) => {
        // Process existing media
        // for (const media of existingMedia) {
        console.log(existingMedia);
        if (existingMedia && !newMedia) {
            await MediaService.updateMedia(existingMedia._id, existingMedia.mediaCaption);
        }
        // }

        // Process new media
        // for (const media of newMedia) {
        if (newMedia) {
            if (existingMedia) {
                await MediaService.deleteMedia(existingMedia._id, story._id);
                setExistingMedia(null);
            }
            console.log(storyId);
            console.log(newMedia);
            const formData = new FormData();
            formData.append('storyId', storyId);
            formData.append('mediaCaption', newMedia.mediaCaption || '');
            formData.append('media', newMedia.file);

            await MediaService.createMedia(formData);
        }
        // }
    };

    const processCopy = async (storyId) => {
        for (const media of storyNewTextMedia) {
            const formData = new FormData();
            formData.append('storyId', storyId);
            formData.append('mediaCaption', '');
            formData.append('isStoryText', true);
            formData.append('media', media);

            await MediaService.createMedia(formData);
        }
    };

    const generateImagesFromPdf = async (pdfArrayBuffer) => {
        console.log(pdfArrayBuffer);
        let generatedFiles = []; // Array to store generated files
        if (pdfArrayBuffer) {
            // Get the PDF document
            const loadingTask = getDocument(pdfArrayBuffer);
            const pdf = await loadingTask.promise;

            // Loop over each page
            for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                const page = await pdf.getPage(pageNum);

                const viewport = page.getViewport({ scale: 2 });
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');

                canvas.height = viewport.height;
                canvas.width = viewport.width;

                await page.render({ canvasContext: context, viewport: viewport }).promise;

                // Convert the canvas to Blob
                const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/png'));
                const file = new File([blob], `page-${pageNum}.png`, { type: 'image/png' });

                generatedFiles.push(file); // Push the file to the array
            }
        }
        setStoryNewTextMedia(generatedFiles);
    };

    const onSubmit = async (values) => {
        let savedStory;

        if (newCopy) {
            values.storyTextMedia = [];
        }

        try {
            if (isNew) {
                savedStory = await StoryService.createStory(values);
            } else {
                savedStory = await StoryService.updateStory(values);
            }

            const storyId = savedStory.data.data._id;
            // Process media files (existing and new)
            await processMedia(storyId);
            await processCopy(storyId);
        } catch (error) {
            console.log(error);
        }
        onHide();
    };

    const openConfirmDialog = (title, message, action, data, button) => {
        setConfirmDialogProps({ title, message, action, data, button });
        setShowConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setShowConfirmDialog(false);
    };

    return (
        <Modal size="xl" show={show} onHide={onHide} centered>
            <Formik initialValues={story} onSubmit={onSubmit} enableReinitialize>
                {({ values, setFieldValue }) => (
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>{isNew ? 'New' : 'Update'} Story</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <BootstrapForm.Group controlId="storyHeaderCopy">
                                        <BootstrapForm.Label>Story Title</BootstrapForm.Label>
                                        <Field
                                            type="text"
                                            name="storyHeaderCopy"
                                            className="form-control"
                                        />
                                    </BootstrapForm.Group>
                                    <BootstrapForm.Group controlId="storySubtitleCopy">
                                        <BootstrapForm.Label>
                                            Story Subtitle (if applicable)
                                        </BootstrapForm.Label>
                                        <Field
                                            type="text"
                                            name="storySubtitleCopy"
                                            className="form-control"
                                        />
                                    </BootstrapForm.Group>
                                </Col>
                                <Col>
                                    <BootstrapForm.Group controlId="storyPillar">
                                        <BootstrapForm.Label>Pillar</BootstrapForm.Label>
                                        <Select
                                            name="storyPillar"
                                            options={storyPillars}
                                            className="basic-select"
                                            classNamePrefix="select"
                                            value={storyPillars.find(
                                                (option) => option.value === values.storyPillar
                                            )}
                                            onChange={(option) =>
                                                setFieldValue('storyPillar', option.value)
                                            }
                                        />
                                    </BootstrapForm.Group>
                                    <BootstrapForm.Group controlId="storyFeatured">
                                        <Field
                                            type="checkbox"
                                            name="storyFeatured"
                                            as={BootstrapForm.Check}
                                            label="Is Featured?"
                                            className="my-3"
                                        />
                                    </BootstrapForm.Group>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <BootstrapForm.Group controlId="storyTextMedia">
                                        <BootstrapForm.Label>Story Main Copy</BootstrapForm.Label>
                                        {storyTextMedia.length > 0 && (
                                            <div>
                                                <label>Existing Story</label>
                                                <div className="d-flex flex-row flex-wrap ">
                                                    {storyTextMedia.map((media, index) => (
                                                        <div
                                                            key={index}
                                                            className="m-1 d-flex align-items-center justify-content-center "
                                                        >
                                                            <div className="border">
                                                                <img
                                                                    src={media.mediaFileName}
                                                                    alt="preview"
                                                                    width="150"
                                                                    onClick={() =>
                                                                        handleMediaViewOpen({
                                                                            mediaType:
                                                                                media.mediaType,
                                                                            mediaFileName:
                                                                                media.mediaFileName
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        <section>
                                            <div {...getCopyRootProps()} className="dropzone">
                                                <input {...getCopyInputProps()} />
                                                <p>
                                                    Drag &apos;n&apos; drop PDF file here, or click
                                                    to select file
                                                </p>
                                            </div>
                                        </section>
                                        {/* <div className="m-2 d-flex flex-column justify-content-center align-items-center">
                                            <Button
                                                variant="primary"
                                                type="button"
                                                onClick={() =>
                                                    window.open(googleDocViewLink, '_blank')
                                                }
                                            >
                                                Open Story Template <FaGoogleDrive />
                                            </Button>

                                            <p className="text-muted text-center">
                                                To make your own editable copy of the template go to
                                                <br />
                                                File &gt; Make a Copy.
                                                <br />
                                                Save it to your google drive and start writing your
                                                stories in google docs.
                                                <br />
                                                Then download your story as a PDF and drag & drop it
                                                above.
                                            </p>
                                        </div> */}
                                        {storyNewTextMedia.length > 0 && (
                                            <div>
                                                <label>New Story</label>
                                                <div className="d-flex flex-row flex-wrap ">
                                                    {storyNewTextMedia.map((media, index) => (
                                                        <div
                                                            key={index}
                                                            className="m-1 d-flex align-items-center justify-content-center "
                                                        >
                                                            <div className="border">
                                                                <img
                                                                    src={URL.createObjectURL(media)}
                                                                    alt="preview"
                                                                    width="150"
                                                                    onClick={() =>
                                                                        handleMediaViewOpen({
                                                                            mediaType: media.type,
                                                                            mediaFileName:
                                                                                URL.createObjectURL(
                                                                                    media
                                                                                )
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </BootstrapForm.Group>
                                </Col>
                                <Col>
                                    <div>
                                        {
                                            existingMedia && (
                                                <div className="mb-3">
                                                    <label>Existing Video</label>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <video
                                                            src={existingMedia.mediaFileName}
                                                            width="150"
                                                            onClick={() =>
                                                                handleMediaViewOpen({
                                                                    mediaType:
                                                                        existingMedia.mediaType,
                                                                    mediaFileName:
                                                                        existingMedia.mediaFileName
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        {existingMedia.mediaCaption !==
                                                            undefined && (
                                                            <BootstrapForm.Control
                                                                className="m-2"
                                                                type="text"
                                                                placeholder="Caption"
                                                                value={
                                                                    existingMedia.mediaCaption || ''
                                                                }
                                                                onChange={(e) => {
                                                                    setExistingMedia({
                                                                        ...existingMedia,
                                                                        mediaCaption: e.target.value
                                                                    });
                                                                }}
                                                            />
                                                        )}
                                                        <Button
                                                            type="button"
                                                            className="m-1"
                                                            variant="danger"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                openConfirmDialog(
                                                                    'Delete Video',
                                                                    'Are you sure you want to delete this Video? This action is irreversible.',
                                                                    removeExistingMediaFile,
                                                                    null,
                                                                    {
                                                                        variant: 'danger',
                                                                        text: 'Delete'
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <FaTrash />
                                                        </Button>
                                                    </div>
                                                </div>
                                            )
                                            // ))}
                                        }
                                    </div>
                                    <BootstrapForm.Group controlId="storyMedia">
                                        <BootstrapForm.Label>Attach Video</BootstrapForm.Label>
                                        <section>
                                            <div {...getMediaRootProps()} className="dropzone">
                                                <input {...getMediaInputProps()} />
                                                <p>
                                                    Drag &apos;n&apos; drop video file here, or
                                                    click to select file
                                                </p>
                                            </div>
                                        </section>
                                    </BootstrapForm.Group>
                                    <div>
                                        {newMedia && (
                                            <div className="mb-3">
                                                <label>New Video</label>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <video
                                                        src={newMedia.url}
                                                        width="150"
                                                        onClick={() =>
                                                            handleMediaViewOpen({
                                                                mediaType: newMedia.file.type,
                                                                mediaFileName: newMedia.url
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    {newMedia.file && (
                                                        <BootstrapForm.Control
                                                            className="m-2"
                                                            type="text"
                                                            placeholder="Caption"
                                                            value={newMedia.mediaCaption || ''}
                                                            onChange={(e) => {
                                                                setNewMedia({
                                                                    ...newMedia,
                                                                    mediaCaption: e.target.value
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                    <Button
                                                        variant="danger"
                                                        className="mx-2"
                                                        onClick={() => removeNewMediaFile()}
                                                    >
                                                        <FaTrash />
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" type="submit">
                                {isNew ? 'Create' : 'Update'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
            {isMediaVisible && <MediaOverlay media={mediaView} onClose={handleMediaViewClose} />}
            <ConfirmDialog
                show={showConfirmDialog}
                onHide={handleCloseConfirmDialog}
                {...confirmDialogProps}
            ></ConfirmDialog>
        </Modal>
    );
};

NewStory.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    _story: PropTypes.object.isRequired
};

export default NewStory;
