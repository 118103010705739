import axios from 'axios';
import config from '../config';
import AWSService from './aws-service';

const base_url = config.base_url;

const axiosInstance = axios.create({
    baseURL: base_url,
    headers: {
        'Content-type': 'application/json'
    }
});

let accessToken = '';
AWSService.getAccessToken().subscribe((res) => {
    accessToken = res;
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    function (request) {
        // // If the request method is POST or PUT, block the request
        // if (request.method.toLowerCase() === 'post' || request.method.toLowerCase() === 'put') {
        //     return Promise.reject(
        //         new Error('POST and PUT requests are blocked during development.')
        //     );
        // }

        // modify the config before sending the request AWSService.getAccessToken()
        request.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';

        return request;
    },
    function (error) {
        // handle the error
        console.log(error);
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    function (response) {
        // modify the response data before returning it
        return response;
    },
    function (error) {
        // handle the error
        console.log(error);

        const {
            config,
            response: { status }
        } = error;
        const originalRequest = config;
        console.log(config);
        console.log(originalRequest);

        if (status === 401) {
            return AWSService.signInWithRefresh(localStorage.getItem('refreshToken')).then(
                (res) => {
                    originalRequest.headers.Authorization = `Bearer ${res.accessToken.jwtToken}`;
                    return axiosInstance(originalRequest);
                }
            );
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
