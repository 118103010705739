import React, { useState } from 'react';
import AWSService from '../../services/aws-service';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { validateYupSchemaMultiErrors } from '../../services/formHelper-service';

function LoginPage() {
    const [errorMessage, setErrorMessage] = useState('');

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [setPwFlow, setSetPwFlow] = useState(false);
    const [resetPwFlow, setResetPwFlow] = useState(false);

    const [cognitoUser, setCognitoUser] = useState();

    AWSService.getCognitoUser().subscribe((res) => {
        if (cognitoUser !== res) {
            setCognitoUser(res);
            if (!res.username) {
                setIsLoggedIn(false);
                setSetPwFlow(false);
                setResetPwFlow(false);
                setErrorMessage(false);
            }
        }
    });

    const NewPasswordSchema = Yup.object().shape({
        newPassword: Yup.string()
            .matches(/(?=.*[a-z])/, 'A Lowercase Character is Required')
            .matches(/(?=.*[A-Z])/, 'An Uppercase Character is Required')
            .matches(/(?=.*[0-9])/, 'A Number is Required')
            .matches(
                /(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])/,
                'A Symbol is Required (e.g. !@#$%)'
            )
            .min(9, 'Minimum Length is 9 Characters')
            .max(256, 'Maximum Length is 256 Characters')
            .required('New Password is Required')
    });

    const LoginSchema = Yup.object().shape({
        email: Yup.string().required('Email is Required'),
        password: Yup.string().required('Password is Required')
    });

    const ResetSchema = Yup.object().shape({
        code: Yup.string().required('Code is Required'),
        newPassword: Yup.string()
            .matches(/(?=.*[a-z])/, 'A Lowercase Character is Required')
            .matches(/(?=.*[A-Z])/, 'An Uppercase Character is Required')
            .matches(/(?=.*[0-9])/, 'A Number is Required')
            .matches(
                /(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])/,
                'A Symbol is Required (e.g. !@#$%)'
            )
            .min(9, 'Minimum Length is 9 Characters')
            .max(256, 'Maximum Length is 256 Characters')
            .required('New Password is Required')
    });

    const handleLoginSubmit = async (vals) => {
        try {
            const loginResult = await AWSService.signInWithEmail(vals.email, vals.password);
            if (loginResult !== 'new_pw_req') {
                setIsLoggedIn(true);
                setSetPwFlow(false);
            } else {
                setIsLoggedIn(true);
                setSetPwFlow(true);
            }
        } catch (error) {
            console.log(error);
            let updateError = true;
            if (error.code === 'PasswordResetRequiredException') {
                setResetPwFlow(true);
                updateError = false;
            }
            setIsLoggedIn(false);
            setSetPwFlow(false);

            if (updateError) {
                setErrorMessage(error.message);
            }
        }
    };

    const handleNewPWSubmit = async (vals) => {
        try {
            // eslint-disable-next-line no-unused-vars
            const newPwResult = await AWSService.handleNewPassword(vals.newPassword);
            setIsLoggedIn(true);
            setSetPwFlow(false);
        } catch (error) {
            console.log(error);
            setErrorMessage(error.message);
        }
    };

    const handleResetPasswordSubmit = async (vals) => {
        try {
            // eslint-disable-next-line no-unused-vars
            const newPwResult = await AWSService.resetPasswordWithCode(vals.code, vals.newPassword);
            setSetPwFlow(false);
        } catch (error) {
            console.log(error);
            setErrorMessage(error.message);
        }
    };

    function resetPwBack() {
        setResetPwFlow(false);
        setErrorMessage('');
    }

    function setPwBack() {
        setSetPwFlow(false);
        setErrorMessage('');
    }

    if (isLoggedIn) {
        if (!setPwFlow) {
            return (
                <div>
                    <div>
                        <p>You are now logged in.</p>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div>
                        <p>Set your new Password</p>
                        <Formik
                            key="spwf"
                            initialValues={{ newPassword: '' }}
                            validateOnMount={true}
                            validate={(values) =>
                                validateYupSchemaMultiErrors(values, NewPasswordSchema)
                            }
                            // eslint-disable-next-line no-unused-vars
                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                await handleNewPWSubmit(values);
                                // resetForm();
                                setSubmitting(false);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isValid,
                                isSubmitting
                            }) => (
                                <Form name="setNewPw" noValidate onSubmit={handleSubmit}>
                                    <Form.Group>
                                        <Form.Label>New Password:</Form.Label>
                                        <Form.Control
                                            required
                                            name="newPassword"
                                            type="password"
                                            className={
                                                errors.newPassword && touched.newPassword
                                                    ? 'is-invalid'
                                                    : ''
                                            }
                                            value={values.newPassword}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.newPassword && touched.newPassword && (
                                            <React.Fragment key={1}>
                                                {errors.newPassword instanceof Array ? (
                                                    errors.newPassword.map((error, index) => (
                                                        <Form.Control.Feedback
                                                            key={index}
                                                            type="invalid"
                                                        >
                                                            {error}
                                                        </Form.Control.Feedback>
                                                    ))
                                                ) : (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.newPassword}
                                                    </Form.Control.Feedback>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Form.Group>
                                    <br />
                                    {errorMessage && <p>{errorMessage}</p>}
                                    <Button className="m-1" type="button" onClick={setPwBack}>
                                        Back
                                    </Button>
                                    <Button
                                        className="m-1"
                                        type="submit"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        Save New Password
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            );
        }
    } else {
        if (resetPwFlow) {
            return (
                <div>
                    <p>
                        Your are required to reset your password. Your reset code should have been
                        sent to your email.
                    </p>
                    <Formik
                        key="rpwf"
                        initialValues={{ code: '', newPassword: '' }}
                        validateOnMount={true}
                        validate={(values) => validateYupSchemaMultiErrors(values, ResetSchema)}
                        // eslint-disable-next-line no-unused-vars
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            await handleResetPasswordSubmit(values);
                            // resetForm();
                            setSubmitting(false);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid
                        }) => (
                            <Form name="resetPw" noValidate onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Code:</Form.Label>
                                    <Form.Control
                                        name="code"
                                        type="text"
                                        className={errors.code && touched.code ? 'is-invalid' : ''}
                                        value={values.code}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                    />
                                    {errors.code && touched.code && (
                                        <Form.Control.Feedback type="invalid">
                                            {errors.code}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                                <br />
                                <Form.Group>
                                    <Form.Label>New Password:</Form.Label>
                                    <Form.Control
                                        name="newPassword"
                                        type="password"
                                        className={
                                            errors.newPassword && touched.newPassword
                                                ? 'is-invalid'
                                                : ''
                                        }
                                        value={values.newPassword}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                    />
                                    {errors.newPassword && touched.newPassword && (
                                        <React.Fragment key={1}>
                                            {errors.newPassword instanceof Array ? (
                                                errors.newPassword.map((error, index) => (
                                                    <Form.Control.Feedback
                                                        key={index}
                                                        type="invalid"
                                                    >
                                                        {error}
                                                    </Form.Control.Feedback>
                                                ))
                                            ) : (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.newPassword}
                                                </Form.Control.Feedback>
                                            )}
                                        </React.Fragment>
                                    )}
                                    <Form.Label className="text-muted">
                                        A password must include at least one lowercase letter, one
                                        uppercase letter, one number, one symbol (e.g. !@#$%), and
                                        be a minimum of 9 characters long.
                                    </Form.Label>
                                </Form.Group>
                                <br />
                                {errorMessage && <p>{errorMessage}</p>}
                                <Button className="m-1" type="button" onClick={resetPwBack}>
                                    Back
                                </Button>
                                <Button
                                    className="m-1"
                                    type="submit"
                                    disabled={!isValid || isSubmitting}
                                >
                                    Reset Password
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            );
        } else {
            return (
                <div>
                    <Formik
                        key="lf"
                        initialValues={{ email: '', password: '' }}
                        validateOnMount={true}
                        validate={(values) => validateYupSchemaMultiErrors(values, LoginSchema)}
                        // eslint-disable-next-line no-unused-vars
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            await handleLoginSubmit(values);
                            // resetForm();
                            setSubmitting(false);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid
                        }) => (
                            <Form name="login" noValidate onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Email:</Form.Label>
                                    <Form.Control
                                        name="email"
                                        type="email"
                                        className={
                                            errors.email && touched.email ? 'is-invalid' : ''
                                        }
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                    />
                                    {errors.email && touched.email && (
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                                <br />
                                <Form.Group>
                                    <Form.Label>Password:</Form.Label>
                                    <Form.Control
                                        name="password"
                                        type="password"
                                        className={
                                            errors.password && touched.password ? 'is-invalid' : ''
                                        }
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                    />
                                    {errors.password && touched.password && (
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    )}
                                    <Form.Label className="text-muted">
                                        A password include at least one lowercase letter, one
                                        uppercase letter, one number, one symbol (e.g. !@#$%), and
                                        be a minimum of 9 characters long.
                                    </Form.Label>
                                </Form.Group>
                                <br />
                                {errorMessage && <p>{errorMessage}</p>}
                                <Button
                                    className="m-1"
                                    type="submit"
                                    disabled={!isValid || isSubmitting}
                                >
                                    Log In
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            );
        }
    }
}

export default LoginPage;
