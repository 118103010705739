import axiosInstance from '../../services/axios-service';

const base_url = '/v1/listing';

class ListingService {
    getListings() {
        return axiosInstance.get(base_url);
    }
    createListing(listing) {
        return axiosInstance.post(base_url, listing);
    }
    getListingById(listingId) {
        return axiosInstance.get(`${base_url}/${listingId}`);
    }
    updateListing(listing) {
        return axiosInstance.put(`${base_url}/${listing._id}`, listing);
    }
    deleteListing(listingId) {
        return axiosInstance.delete(`${base_url}/${listingId}`);
    }
}

const listingServiceInstance = new ListingService();
export default listingServiceInstance;
