import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DashboardService from './DashboardService';
import PropTypes from 'prop-types';
import { Formik } from 'formik'; // Import Formik-related components
// Removed ReactQuill import

const UpdateMessageModal = ({ show, onHide, messageIn, keyIn, onUpdate }) => {
    const [message, setMessage] = useState('');

    useEffect(() => {
        setMessage(messageIn);
    }, [messageIn]);

    const submitHandler = async (values, { resetForm }) => {
        const obj = {};
        obj[keyIn] = values;
        console.log(obj);
        try {
            await DashboardService.updateDashboard(obj);
            if (onUpdate) {
                onUpdate();
            }
        } catch (error) {
            console.log(error);
        }
        onHide();
        resetForm();
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Formik
                initialValues={{ message: message }}
                onSubmit={(values, actions) => submitHandler(values.message, actions)}
                enableReinitialize
            >
                {({ handleSubmit, setFieldValue, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Update Message</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label htmlFor="message">Enter your new message</label>
                            <textarea
                                id="message"
                                value={values.message}
                                onChange={(e) => setFieldValue('message', e.target.value)}
                                style={{ width: '100%', height: '150px' }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                        </Modal.Footer>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

UpdateMessageModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    messageIn: PropTypes.string.isRequired,
    keyIn: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired
};

export default UpdateMessageModal;
