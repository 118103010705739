import React, { useState, useEffect } from 'react';
import CategoryService from '../category/CategoryService';
import CategoryItem from './CategoryItem';
import DropZone from './DropZone';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';

function PlanetConfig() {
    const [categories, setCategories] = useState({
        unassigned: [],
        planet1: [],
        planet2: []
    });

    const GetData = async () => {
        const results = await CategoryService.getCategories();
        const splitCategories = results.data.data.reduce(
            (acc, category) => {
                if (category.categoryPlanet === 1) {
                    acc.planet1.push(category);
                } else if (category.categoryPlanet === 2) {
                    acc.planet2.push(category);
                } else {
                    acc.unassigned.push(category);
                }
                return acc;
            },
            { unassigned: [], planet1: [], planet2: [] }
        );
        setCategories(splitCategories);
    };

    useEffect(() => {
        GetData();
    }, []);

    const renderCategoryItems = (categoryList) =>
        categoryList.map((category, index) => (
            <Draggable key={category._id} draggableId={category._id} index={index}>
                {(provided) => (
                    <CategoryItem
                        key={category._id}
                        category={category}
                        provided={provided}
                        innerRef={provided.innerRef}
                    />
                )}
            </Draggable>
        ));

    async function onDragEnd(result) {
        const { source, destination } = result;
        console.log(source);
        console.log(destination);
        // Case 1: Dropped outside of any dropzone
        if (!destination) {
            return;
        }

        // Case 2: Dropped in a full dropzone
        if (
            (destination.droppableId === 'planet1' && categories.planet1.length >= 9) ||
            (destination.droppableId === 'planet2' && categories.planet2.length >= 9)
        ) {
            return;
        }

        // Create a deep copy of the categories state
        const newCategories = JSON.parse(JSON.stringify(categories));

        // Remove the item from the source dropzone
        const [removed] = newCategories[source.droppableId].splice(source.index, 1);

        // Case 3 & 4: Add the item to the destination dropzone at the specified index
        newCategories[destination.droppableId].splice(destination.index, 0, removed);

        // Update the categories state with the new arrangement
        const results = await CategoryService.updatePlanets({
            planet1: newCategories.planet1,
            planet2: newCategories.planet2
        });
        console.log(results);
        setCategories(newCategories);
    }

    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Planet Configurator</h1>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="row">
                    <div className="col">
                        <DropZone zoneName={'Unassigned'} droppableId={'unassigned'} zoneNumber={0}>
                            {renderCategoryItems(categories.unassigned)}
                        </DropZone>
                    </div>
                    <div className="col">
                        <DropZone
                            zoneName={'Planet 1'}
                            droppableId={'planet1'}
                            zoneNumber={1}
                            maxItems={9}
                        >
                            {renderCategoryItems(categories.planet1)}
                        </DropZone>
                    </div>
                    <div className="col">
                        <DropZone
                            zoneName={'Planet 2'}
                            droppableId={'planet2'}
                            zoneNumber={2}
                            maxItems={9}
                        >
                            {renderCategoryItems(categories.planet2)}
                        </DropZone>
                    </div>
                </div>
            </DragDropContext>
        </div>
    );
}

export default PlanetConfig;
